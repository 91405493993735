
import { defineComponent, inject } from 'vue'

export default defineComponent({
    props: { type: String || undefined },
    emits: ['open'],
    setup(props) {
        const exchangeStore: any = inject('exchangeStore')

        let name = 'Error'
        let image = '@/assets/images/logo-paypal.png'
        let pText = 'Testers_redeem_index_watcoins_euros'
        let smallText = 'Testers_redeem_index_min_price_watcoins'
        switch (props.type) {
            case exchangeStore.state.redeemBox.types.PAYPAL:
                name = 'Paypal'
                image = require('@/assets/images/logo-paypal.png')
                pText = 'Testers_redeem_index_watcoins_euros'
                smallText = 'Testers_redeem_index_min_price_watcoins'
                break
            case exchangeStore.state.redeemBox.types.AMAZON:
                name = 'Amazon'
                image = require('@/assets/images/logo-amazon.png')
                pText = 'Testers_redeem_index_watcoins_giftcards'
                smallText = 'Testers_redeem_index_min_price_watcoins'
                break
            case exchangeStore.state.redeemBox.types.CRUZROJA:
                name = 'Donación'
                image = require('@/assets/images/logo-cruzroja.png')
                pText = 'Testers_redeem_index_ong'
                smallText = 'Testers_redeem_index_min_price_watcoins'
                break
            case exchangeStore.state.redeemBox.types.FNAC:
                name = 'Fnac'
                image = require('@/assets/images/logo-fnac.svg')
                pText = 'Testers_redeem_index_watcoins_fnac'
                smallText = 'Testers_redeem_index_min_20_watcoins'
                break
            case exchangeStore.state.redeemBox.types.STRADIVARIUS:
                name = 'Stradivarius'
                image = require('@/assets/images/logo-stradivarius.svg')
                pText = 'Testers_redeem_index_watcoins_giftcards_online'
                smallText = 'Testers_redeem_index_min_30_watcoins'
                break
            case exchangeStore.state.redeemBox.types.ATRAPALO:
                name = 'Atrápalo'
                image = require('@/assets/images/logo-atrapalo.png')
                pText = 'Testers_redeem_index_atrapalo_company'
                smallText = 'Testers_redeem_index_min_25_watcoins'
                break
            case exchangeStore.state.redeemBox.types.ASOS:
                name = 'Asos'
                image = require('@/assets/images/logo-asos.png')
                pText = 'Testers_redeem_index_asos_company'
                smallText = 'Testers_redeem_index_min_price_watcoins'
                break
            case exchangeStore.state.redeemBox.types.DECATHLON:
                name = 'Decathlon'
                image = require('@/assets/images/logo-decathlon.png')
                pText = 'Testers_redeem_index_decathlon_company'
                smallText = 'Testers_redeem_index_min_price_watcoins'
                break
            case exchangeStore.state.redeemBox.types.TREE_NATION:
                name = 'Tree Nation'
                image = require('@/assets/images/logo-tree-nation.svg')
                pText = 'Testers_redeem_index_tree_nation_company'
                smallText = 'Testers_redeem_index_min_price_watcoins'
                break
            default:
                break
        }
        return {
            name,
            image,
            pText,
            smallText,
        }
    },
})
