import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9f40275a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "exchangeBoxContainer" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", { src: _ctx.image }, null, 8, _hoisted_2),
    _createElementVNode("p", null, _toDisplayString(_ctx.$t(_ctx.pText)), 1),
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('open'))),
      id: "exchangeBoxButton"
    }, _toDisplayString(_ctx.$t('Testers_redeem_index_exchange_reward')), 1),
    _createElementVNode("small", null, _toDisplayString(_ctx.$t(_ctx.smallText)), 1)
  ]))
}